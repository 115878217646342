@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@layer base {
  * {
    font-family: poppins, system-ui, sans-serif;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

// Temporary fix for bug with angular material outline inputs and tailwind (outline looks weird on focus)
.mdc-notched-outline__notch {
  border-right: none;
}

.attune-modal .mat-mdc-dialog-surface {
  border-radius: 0.75rem;
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mdc-checkbox__ripple {
  background-color: #275a78 !important;
  border-color: #275a78 !important;
}
